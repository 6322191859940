<template>
  <c-flex flex-direction="column" min-height="100vh">
    <Header :logo="'hide'" back-title="Shopping Cart" />

    <c-box
      paddingTop="30px"
      :paddingBottom="[null, '80px']"
      px="20px"
      :height="['calc(100vh - 200px)', null]"
      :overflow-y="['scroll', 'unset']"
    >
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            { label: 'Keranjang', isCurrent: true },
          ]"
        />
        <c-heading
          :display="['none', 'inherit']"
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          mt="30px"
          mb="40px"
        >
          Shopping Cart
        </c-heading>

        <c-flex
          v-if="isEmpty"
          flex="1"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          :w="['100%', null]"
          :position="['absolute', 'relative']"
          :top="['50%', 'unset']"
          :transform="['translateY(-50%)', 'unset']"
        >
          <c-image
            :h="['203px', '300px']"
            :src="require('@/assets/img-cart-empty.svg')"
            alt="empty cart illustration"
          />
          <c-text
            mt="21px"
            color="gray.900"
            :fontSize="['14px', '24px']"
            lineHeight="36px"
          >
            Keranjang kosong, silahkan pilih program
          </c-text>
          <c-button
            variant="outline"
            border-radius="25px"
            variant-color="brand"
            fontSize="16px"
            fontWeight="bold"
            right-icon="arrow-forward"
            mt="35px"
            py="13px"
            px="28px"
            h="50px"
            as="router-link"
            to="/#program"
          >
            Pilih program
          </c-button>
        </c-flex>

        <c-flex
          v-if="!isEmpty"
          :flex-direction="['column', 'row']"
          :mx="['initial', 'auto']"
        >
          <c-grid
            w="100%"
            maxWidth="1200px"
            h="auto"
            mx="auto"
            :border="[null, '1px solid #F2F2F2']"
            :boxShadow="[null, '4px 4px 50px 5px rgba(0, 0, 0, 0.05)']"
            borderRadius="6px"
          >
            <c-flex
              :display="['none', 'flex']"
              justify-content="space-between"
              align-items="center"
              py="12px"
              px="47px"
              background="#F2F2F2"
              borderRadius="6px 6px 0px 0px"
            >
              <c-text
                flex-basis="60%"
                fontSize="16"
                fontWeight="500"
                lineHeight="27px"
              >
                NAMA PROGRAM
              </c-text>
              <c-flex flex-basis="40%" w="50%" justify-content="space-between">
                <c-text fontSize="16" fontWeight="500" lineHeight="27px">
                  HARGA
                </c-text>
                <c-text
                  w="50%"
                  fontSize="16"
                  fontWeight="500"
                  lineHeight="27px"
                  text-align="center"
                >
                  CHECK OUT
                </c-text>
              </c-flex>
            </c-flex>
            <c-flex
              v-for="(item, index) in cartItems"
              :key="index"
              justify-content="space-between"
              align-items="center"
              py="26px"
              :px="[null, '47px']"
              borderBottom="1px solid #F2F2F2"
            >
              <c-flex align-items="center" w="100%" :flexBasis="[null, '60%']">
                <c-button
                  variant-color="brand"
                  variant="link"
                  :display="['inherit', 'none']"
                  mr="10px"
                  @click="selectedItem = item"
                >
                  <c-image
                    :src="
                      selectedItem.id === item.id
                        ? require('@/assets/icon-ellipse-active.svg')
                        : require('@/assets/icon-ellipse.svg')
                    "
                    alt="radio"
                  />
                </c-button>
                <c-box w="92px" h="92px" borderRadius="3px" mr="20px">
                  <c-image
                    :src="getPhotoProgram(item.photoUrl)"
                    :alt="item.productName"
                  />
                </c-box>
                <c-box :flexGrow="[1, null]">
                  <c-heading
                    as="h3"
                    fontFamily="Roboto"
                    fontWeight="500"
                    color="black.900"
                    :fontSize="['16px', '18px']"
                    lineHeight="27px"
                  >
                    {{ item.productName }}
                    {{ item.programService }}
                    <c-box display="block">{{ item.duration }} Hari</c-box>
                  </c-heading>
                  <c-flex
                    :display="['flex', 'none']"
                    mt="20px"
                    justify="space-between"
                  >
                    <c-text
                      fontWeight="700"
                      color="brand.900"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      Rp{{ Number(item.productPrice).toLocaleString("id") }}
                    </c-text>
                    <c-button
                      fontFamily="Roboto"
                      fontWeight="normal"
                      fontSize="16px"
                      variant="link"
                      ml="32px"
                      @click="onDelete(item.id)"
                    >
                      <c-image
                        :src="require('@/assets/icon-trash-line.svg')"
                        alt="trash"
                      />
                    </c-button>
                  </c-flex>
                  <c-button
                    :display="['none', 'inherit']"
                    fontFamily="Roboto"
                    fontWeight="normal"
                    fontSize="16px"
                    right-icon="arrow-forward"
                    color="brand.900"
                    variant="link"
                    py="8px"
                    @click="onDetailProduct(item)"
                  >
                    Selengkapnya
                  </c-button>
                </c-box>
              </c-flex>
              <c-flex
                :display="['none', 'flex']"
                flex-basis="40%"
                w="60%"
                align-items="center"
                justify-content="space-between"
              >
                <c-text
                  fontWeight="500"
                  color="brand.900"
                  fontSize="24px"
                  lineHeight="36px"
                >
                  Rp{{ Number(item.productPrice).toLocaleString("id") }}
                </c-text>
                <c-flex w="50%" justify="space-between">
                  <c-button
                    fontFamily="Roboto"
                    fontWeight="normal"
                    variant="outline"
                    fontSize="16px"
                    borderRadius="40px"
                    color="brand.900"
                    variant-color="brand.900"
                    px="46px"
                    py="25px"
                    @click="onCheckout(item)"
                  >
                    Check Out
                  </c-button>
                  <c-button
                    fontFamily="Roboto"
                    fontWeight="normal"
                    fontSize="16px"
                    variant="link"
                    @click="onDelete(item.id)"
                  >
                    <c-image
                      :src="require('@/assets/icon-trash-line.svg')"
                      alt="trash"
                    />
                  </c-button>
                </c-flex>
              </c-flex>
            </c-flex>
          </c-grid>
        </c-flex>
      </c-box>
    </c-box>

    <c-box
      v-if="selectedItem"
      :display="['grid', 'none']"
      position="fixed"
      bottom="60px"
      w="100%"
      p="20px"
      background="#FFFFFF"
      box-shadow="4px -4px 50px 5px rgba(0, 0, 0, 0.15)"
    >
      <c-flex mb="20px" align-items="center" justify-content="space-between">
        <c-text color="gray.900" fontSize="12px" lineHeight="36px">
          Subtotal
        </c-text>
        <c-text
          fontWeight="500"
          color="brand.900"
          fontSize="14px"
          lineHeight="16px"
        >
          Rp{{ Number(selectedItem.productPrice).toLocaleString("id") }}
        </c-text>
      </c-flex>

      <c-button
        fontFamily="Roboto"
        fontWeight="normal"
        variant="solid"
        fontSize="16px"
        borderRadius="40px"
        variant-color="brand"
        px="46px"
        py="24px"
        @click="onCheckout(selectedItem)"
      >
        Check Out
      </c-button>
    </c-box>
    <NavBottom />
  </c-flex>
</template>

<script>
import NavBottom from "@/components/nav-bottom";
import Header from "@/components/header/index.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import { mapActions, mapGetters } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "CartPage",
  components: { Header, NavBottom, Breadcrumb },
  mixins: [generalMixin],
  data() {
    return {
      selectedItem: "",
    };
  },
  computed: {
    ...mapGetters({
      isEmptyCart: "cart/isEmpty",
      cartItems: "cart/items",
    }),
    isEmpty() {
      return this.isEmptyCart;
    },
  },
  mounted() {
    this.listCart();
  },
  methods: {
    ...mapActions({
      listCart: "cart/list",
      onSubmitCheckout: "cart/onSubmitCheckout",
      onDeleteProduct: "cart/onDeleteProduct",
      detailProduct: "clients/getProductById",
      onOpenModal: "clients/onToggleModal",
    }),
    onCheckout(item) {
      this.onSubmitCheckout(item).then(() =>
        this.$router.push({ name: "client.checkout" })
      );
    },
    onDelete(id) {
      this.onDeleteProduct(id);
    },
    onDetailProduct(item) {
      this.$router.push({ name: "client.index", hash: "#program" });
      this.detailProduct(item.productId).then(() => this.onOpenModal(true));
    },
  },
};
</script>

<style scoped>
</style>
